import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProjectsTeaser.module.less';
import {Button, Col, Divider, Row, Typography} from 'antd';
import {useIntl} from 'gatsby-plugin-intl';
import {Link} from 'gatsby';
import TeaserPost from '../teaser-post/TeaserPost';

const ProjectsTeaser = ({ postsList, plain = false }) => {
    const {formatMessage, locale} = useIntl();
    const {Title, Text} = Typography;

    return (postsList.length > 0 &&
        <div className={`${styles.section} ${plain ? '' : styles.sectionWhite}`}>
            <div className="container">
                {!plain && (
                    <>
                        <Divider className={styles.divider} />
                        <Title level={3} className={styles.title}>{formatMessage({id: 'teaser.title'})}</Title>
                        <Text type="paragraph" className={styles.subheading}>
                            {formatMessage({id: 'projects-teaser.text'}, {strong: (...chunks) => <strong>{chunks}</strong>})}
                        </Text>
                    </>
                )}
                <Row gutter={{ xs: 0, md: 30, lg: 40, xl: 60 }}>
                    {postsList.slice(0, 3).map(post => (
                        <Col xs={12} md={6} lg={6} xl={4}>
                            <TeaserPost post={post} withImage={!plain} />
                        </Col>
                    ))}
                </Row>
                <Link to={`/${locale}/magazine`} className={styles.buttonContainer}>
                    <Button type="ghost">
                        {formatMessage({id: 'teaser.button'})}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

ProjectsTeaser.propTypes = {
    postsList: PropTypes.array.isRequired,
    plain: PropTypes.bool
}

export default ProjectsTeaser;
