import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';
import Text from 'antd/es/typography/Text';
import styles from './TeaserPost.module.less';
import {Typography} from 'antd';

const TeaserPost = ({post, withImage}) => {
    const {locale} = useIntl();
    const {Title} = Typography;

    return (
        <>
            <Link to={`/${locale}/magazine/${post.slug}`} className={styles.postWrapper}>
                {withImage && (
                    <div className={styles.imageWrapper}>
                        <img
                            src={post.heroImage.fixed.src}
                            alt={post.title}
                            className={styles.image}
                        />
                    </div>
                )}
                <Title
                    level={4}
                    className={styles.title}
                    style={{borderColor: post.category.color, color: post.category.color}}
                >
                    <span className="text">{post.title}</span>
                </Title>
            </Link>
            <Text className={styles.content}>{post.excerpt.slice(0, 200)}...</Text>
        </>
    );
};

TeaserPost.propTypes = {
    withImage: PropTypes.bool,
    post: PropTypes.object.isRequired,
}

export default TeaserPost;
