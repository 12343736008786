import React from 'react';
import {Card, Col, Row, Typography} from 'antd';
import styles from './ShortContact.module.less';
import {useIntl} from 'gatsby-plugin-intl';
import ShortContactForm from './ShortContactForm';

const ShortContact = () => {
    const {Title, Text} = Typography;
    const {formatMessage} = useIntl();

    return (
        <div className={styles.section}>
            <div className="container">
                <Row gutter={[{xs: 0, lg: 60}, {xs: 40, sm: 40}]} type="flex" justify="center">
                    <Col className={styles.firstColumn}>
                        <Title level={1} className="custom-title">
                            <span className="word animated">{formatMessage({id: 'contact-block.title.first-word'})}</span> {formatMessage({id: 'contact-block.title.rest'})}
                        </Title>
                        <Text className={styles.description}>{formatMessage({id: 'contact-block.description'})}</Text>
                    </Col>
                    <Col>
                        <div className={styles.animated}>
                            <Card className={styles.card} bordered={false}>
                                <div className={styles.cardHead}>{formatMessage({id: 'contact-block.form.title'})}</div>
                                <ShortContactForm />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ShortContact;
