import React, {createRef, useState} from 'react';
import {Form, Input, Button, Typography} from 'antd';
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl';
import {useCustomForm} from '../../hooks/useCustomForm';
import Recaptcha from '../recaptcha/Recaptcha';


const ShortContactForm = () => {
    const locale = useIntl().locale;
    const {TextArea} = Input;
    const {Text} = Typography;
    const {formatMessage} = useIntl();
    const recaptchaRef = createRef();
    const [form] = Form.useForm();
    const {handleSendForm, isFormValid, handleValidation, sendStatus, getFieldError} = useCustomForm(form);

    const onFinish = () => {
        recaptchaRef.current.execute();
    };

    const handleCaptchaResolved = (response) => {
        handleSendForm(response);
    };
    const [emailValidateTrigger, setEmailValidateTrigger] = useState('onBlur');
    const validateTrigger = () => (getFieldError('email').length > 0 ? 'onChange' : 'onBlur');

    return (
        <Form
            form={form}
            name="short-contact-form"
            onFieldsChange={handleValidation}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                    {required: true, message: formatMessage({id: 'validation.email.required'})},
                    {type: 'email', message: formatMessage({id: 'validation.email.invalid'})},
                ]}
                validateTrigger={emailValidateTrigger}
            >
                <Input
                    placeholder={formatMessage({id: 'contact-form.email.placeholder'})}
                    onChange={() => setEmailValidateTrigger(validateTrigger())}
                />
            </Form.Item>
            <Form.Item
                extra={
                    <FormattedMessage
                        id={'contact-form.captcha'}
                        values={{
                            a1: (chunks) => <a href={`https://www.google.com/intl/${locale}/policies/privacy/`} target='_blank' rel='noopener noreferrer'>{chunks}</a>,
                            a2: (chunks) => <a href={`https://www.google.com/intl/${locale}/policies/terms/`} target='_blank' rel='noopener noreferrer'>{chunks}</a>
                        }}
                    >
                        {(chunks) => <Text type={'secondary'} className="caption">{chunks}</Text>}
                    </FormattedMessage>
                }
                name="message"
                rules={[{
                    required: true,
                    message: formatMessage({id: 'validation.message.required'}),
                    whitespace: true,
                }]}
            >
                <TextArea
                    placeholder={formatMessage({id: 'contact-form.textarea.placeholder'})}
                    rows={5}
                    style={{resize: 'none'}}
                />
            </Form.Item>
            <Recaptcha
                recaptchaRef={recaptchaRef}
                onVerify={handleCaptchaResolved}
            />
            <Form.Item
                className="margin-bottom-0"
                validateStatus={sendStatus}
                help={sendStatus !== '' && formatMessage({id: `contact-form.send.${sendStatus}`})}
            >
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isFormValid}
                >
                    {formatMessage({id: 'contact-form.button.send'})}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ShortContactForm;
